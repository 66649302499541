<template>
  <div class="container">
    <div class="row mt-4">
      <div class="col-md-6 offset-md-3">
        <h4 class="font-weight-bold">{{ 'settings.twoFactorSetupTitle' | translate }}</h4>
      </div>
    </div>
    <div class="mt-4" v-if="!data.options">
      <loader />
    </div>
    <div class="row mt-4 mb-5" v-if="data.options">
      <div class="col-sm-12 col-md-6 offset-md-3">
        <p class="text-left">{{ 'settings.twoFactorSetupHelpText' | translate }}</p>
        <div class="row mt-5">
          <div class="col-md-5 text-center">
            <div class="text-center">
              <img :src="data.options.qrCodeImageUri" />
            </div>
            <small>{{ data.options.key }}</small>
          </div>
          <div class="col-md-7">
            <div class="form-group mt-4">
              <i class="material-icons lock-icon">lock</i>
              <input
                required
                inputmode="numeric"
                maxlength="6"
                minlength="6"
                v-validate="{ required: true, regex: /^([0-9][0-9][0-9][0-9][0-9][0-9])/ }"
                type="text"
                class="form-control w-75 d-inline"
                v-model="authCode"
                :placeholder="$t('settings.twoFactorPlaceholder')"
              />
              <br />
              <secondary-button :disabled="loading" class="mt-4" @click="cancel()">{{
                'general.cancel' | translate
              }}</secondary-button>
              <primary-button
                class="ml-3 btn btn-primary mt-4"
                :disabled="!authCode || authCode.length !== 6"
                :loading="loading"
                @click="confirmAuthCode()"
                >{{ 'settings.confirm' | translate }}</primary-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- TODO check if this modal select is needed per template, i guess not -->
    <sweet-modal
      :title="$t('login.chooseApp')"
      v-model="showModal"
      :blocking="true"
      width="400"
      ref="template-modal"
      id="template-modal"
    >
      <div class="modal-body">
        <div class="row">
          <div
            class="col-md-4 text-center pointer"
            v-for="onboarding in onboardings"
            :key="onboarding.id"
            @click="selectOnboarding(onboarding)"
          >
            <img
              v-if="onboarding.template.imageURL"
              :src="onboarding.template.imageURL"
              :alt="onboarding.template.name"
              class="img-fluid"
              width="100%"
              height="auto"
            />
            <img
              v-else
              src="../../../shared/assets/img/app-placeholder.png"
              :alt="onboarding.template.name"
              class="img-fluid"
              width="100%"
              height="auto"
            />
            <h5>{{ onboarding.template.name }}</h5>
          </div>
        </div>
      </div>
      <div slot="modal-footer" class="modal-footer"></div>
    </sweet-modal>
  </div>
</template>

<script>
import authMixin from '@app/mixins/auth';
import { SweetModal } from 'sweet-modal-vue';

import { getCompanyMfaConfigurations, getUserMfaSetup, confirmUserMfaSetup } from '../../http/authentication';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { mapGetters } from 'vuex';
const { VUE_APP_API_URL, VUE_APP_API_AUTH_URL, VUE_APP_AUTH_CLIENT_ID } = process.env;

export default {
  mixins: [authMixin],
  components: {
    SweetModal
  },
  computed: {
    ...mapGetters('settings', ['subdomain'])
  },
  created() {
    getCompanyMfaConfigurations(this.company.id).then((response) => {
      this.configs = response.data;
      this.userId = jwtDecode(this.$store.state.auth.token).sub;
      const providerType = this.configs[0]?.type || 1;
      getUserMfaSetup(this.userId, providerType).then(
        (response) => (this.data = response.data),
        (error) => {
          this.$router.push({ path: '/auth/login' });
        }
      );
    });
  },
  data() {
    return {
      authCode: null,
      data: {},
      configs: [],
      loading: false,
      userId: null,
      showModal: false
    };
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    confirmAuthCode() {
      this.loading = true;
      const twoFactorSetup = this.$store.state.auth.twoFactorSetup;
      confirmUserMfaSetup(this.userId, {
        providerType: this.data.providerType,
        code: this.authCode,
        id: this.data.id,
        role: twoFactorSetup.forAdmin ? 'admin' : 'onboardee'
      }).then(
        (response) => {
          if (twoFactorSetup.forAdmin) {
            const payload = new URLSearchParams();
            payload.append('grant_type', 'mfa_code');
            payload.append('scope', 'offline_access admin');
            payload.append('role', 'admin');
            payload.append('subdomain', 'testingcompany');
            payload.append('mfa_code', this.authCode);
            payload.append('mfa_type', 1);
            payload.append('token', response.data.accessToken);

            axios
              .post(`${VUE_APP_API_AUTH_URL}/connect/token`, payload, {
                headers: {
                  Authorization: `Basic ${VUE_APP_AUTH_CLIENT_ID}`,
                  'Content-Type': 'application/x-www-form-urlencoded'
                }
              })
              .then((response) => {
                this.$store.dispatch('auth/setToken', {
                  token: response.data.access_token,
                  remember: true
                });
                if (response.data.refresh_token) {
                  this.$store.dispatch('auth/setRefreshToken', {
                    refresh_token: response.data.refresh_token
                  });
                }

                this.$http.get('/v1/identity').then((res) => {
                  this.$store.dispatch('auth/setUser', res.data);

                  const redirectPath = this.$route.query.redirect_to || '/adm';
                  if (redirectPath !== '/adm') {
                    window.location.href = redirectPath;
                  } else {
                    this.$router.push('/adm');
                  }
                });
              });
            return;
          }

          const token = response.data.accessToken;
          if (token) {
            this.$notify({
              type: 'success',
              message: this.$t('settings.twoFactorAuthenticationEnabled'),
              position: 'bottom-right'
            });

            this.$store.dispatch('auth/setToken', { token, remember: true });
            this.$http.get('/v2/users/data').then((response) => {
              let user = this.createUserWithTokenAndLanguage(token, response.data.language);
              user = { ...response.data, ...user };
              this.onboard(user);
            });
          } else {
            this.$notify({
              type: 'error',
              message: this.$t('notifications.error'),
              position: 'bottom-right'
            });
          }
          setTimeout(() => {
            this.loading = false;
          }, 700);
        },
        (error) => {
          this.loading = false;
          if (error.data.errors?.length > 0) {
            return this.$notify({
              type: 'error',
              message: error.data.errors[0],
              position: 'bottom-right'
            });
          }
          this.$notify({
            type: 'error',
            message: this.$t('notifications.error'),
            position: 'bottom-right'
          });
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.lock-icon {
  font-size: 24px;
  margin-right: 12px;
  transform: translateY(7px);
}
</style>
